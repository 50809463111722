<template>
  <div v-if="$userInfo.isSupportUser"
    class="tile is-parent pl-1">
    <section class="tile is-child box">
      <div>
        <span class="subtitle">Users With Access To Xero Export</span>
      </div>
      <div class="pt-5">
        <Multiselect v-model="usersWithAccess"
          :options="users"
          :hide-selected="true"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :show-labels="false"
          :preserve-search="true"
          placeholder="Please Select At Least One User"
          track-by="userId"
          label="username" />
      </div>
      <div class="pt-5">
        <button class="button is-primary"
          @click="updateUserAccess(usersWithAccess)">Update User Access</button>
      </div>
    </section>
  </div>
  <div v-else
    class="is-flex is-justify-content-center pt-5 ">
    <div class="notification is-danger is-flex is-justify-content-center is-size-4"
      style="width: 50%">
      You do not have permissions on this page
    </div>
  </div>
</template>

<script>

import { CompanyService } from '@/services'
import Multiselect from 'vue-multiselect'

export default {
  name: 'CompanyUpdateUserAccess',

  components: {
    Multiselect
  },

  data: () => {
    return {
      users: [],
      usersWithAccess: []
    }
  },

  async created() {
    await this.fetchUsers()
    await this.fetchUsersWithXeroAccess()
  },

  methods: {
    async updateUserAccess(user) {
      if (!user) return this.$notification.error('Error', 'Please select at least one user'	)
      const userIds = user.map(u => ({ userId: u.userId }))
      try {
        this.$showSpinner()
        await CompanyService.updateUserAccess(userIds)
        this.$hideSpinner()
        this.$notification.success('Success', 'Access updated')
      } catch (error) {
        this.$hideSpinner()
        this.$notification.error('Error', 'Failed update user access')
      }
    },
    async fetchUsers() {
      const response = await CompanyService.getCompanyUsers()
      this.users = response.sort((a,b) => a.username.localeCompare(b.username))
    },
    async fetchUsersWithXeroAccess() {
      const response = await CompanyService.getCompanyUsersWithXeroAccess()
      this.usersWithAccess = this.users.filter(u => response.some(r => r.userId === u.userId))
    }
  }
}
</script>
