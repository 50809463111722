<template>
  <div v-if="$userInfo.isSupportUser"
    class="tile is-parent pl-1">
    <section class="tile is-child box">
      <div>
        <span class="subtitle">Available New Menus</span>
        <ul class="list-style-reset">
          <li v-for="menu in menus"
            :key="menu.menuCode">
            <span>{{ menu.menuName }}</span>
          </li>
        </ul>
      </div>
      <div class="pt-5">
        <button class="button is-primary"
          @click="grantNewMenusAccess()">Grant Access</button>
      </div>
    </section>
  </div>
  <div v-else
    class="is-flex is-justify-content-center pt-5 ">
    <div class="notification is-danger is-flex is-justify-content-center is-size-4"
      style="width: 50%">
      You do not have permissions on this page
    </div>
  </div>
</template>

<script>
// import AppDetailHeader from '@/components/AppDetailHeader'
import Menus from './new-menus'
import { CompanyService } from '@/services'

export default {
  name: 'CompanyEnableNewMenu',
  components: {
    // AppDetailHeader
  },
  mixins: [],
  props: {},
  data: () => {
    return {}
  },
  computed: {
    menus() {
      return Menus
    }
  },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    async grantNewMenusAccess() {
      try {
        this.$showSpinner()
        let promises = []
        this.menus.forEach((m) => {
          promises.splice(promises.length, 1, CompanyService.grantNewMenuAccess(m))
        })
        await Promise.all(promises)
      } catch (e) {
        this.$notification.error('New Menu Access Error', `Update failed for Access. Error: ${e}`)
      } finally {
        this.$hideSpinner()
        this.$notification.success('New Menu Access', 'Menus Access Granted')
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.list-style-reset {
  margin-top: 1em;
  li {
    list-style: initial;
    list-style-type: disc;
    margin-left: 2em;
  }
}
</style>
